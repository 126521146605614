@font-face {
  font-family: 'Lato-BlackItalic';
  src: url('fonts/lato/Lato-BlackItalic.ttf') format('ttf');
}

body a{
  font-family: 'Verdana';
}

html, body, #root, #root>div {
  height: 100vh;
  overflow: hidden;
  scroll: auto;
}
